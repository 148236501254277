var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.popup && _vm.show ? _c('div', {
    staticClass: "popup-layer-container",
    style: _vm.popupStyle
  }, [_c('a', {
    staticClass: "popup-layer-body",
    attrs: {
      "href": _vm.popup.url,
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "popup-layer-content",
    domProps: {
      "innerHTML": _vm._s(_vm.popup.content.replace(/\n/g, '<br>'))
    }
  })]), _c('div', {
    staticClass: "popup-layer-foot"
  }, [_c('v-checkbox', {
    attrs: {
      "value": true,
      "label": "오늘하루 열지않음",
      "dark": "",
      "hide-details": ""
    },
    model: {
      value: _vm.doNotOpenToday,
      callback: function ($$v) {
        _vm.doNotOpenToday = $$v;
      },
      expression: "doNotOpenToday"
    }
  }), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("닫기")])], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }